import { VerificationStatus, QuestionTypes, PlanStatus } from '../app/store/remediationFilterSlice';
import  { Question } from '../interfaces/question.interface';
import { AssessorAnswer, BPVerification } from '../interfaces/assessment.interface';
import moment from 'moment';
import { BestPractice } from '../interfaces/bestPractice.interface';
import { REMEDIATION_OPTIONS } from './surveyHelpers';

export const remediationFilter = (
  filteredVerificationStatus: VerificationStatus,
  filteredQuestionType: QuestionTypes,
  filteredPlanStatus: PlanStatus,
  filteredPastDue: boolean | null,
  question:  Question,
  assessmentQuestion?: AssessorAnswer,
  bpVerificationList?: BPVerification[],
): boolean => {
  if (!assessmentQuestion) return true;

  const { remediationQuestionAnswers = [], vendorStatus, verificationStatus, status, remediationDate } = assessmentQuestion || {};

  // Filter by Question Type
  if (filteredQuestionType) {
    if (filteredQuestionType === 'Best Practice' && !question.isBestPractice) {
      return false;
    } else if (filteredQuestionType === 'Additional Recommendation' && question.isBestPractice) {
      return false;
    }
  }

  // Filter by Remediation Plan Status
  if (filteredPlanStatus) {
    if (filteredPlanStatus === 'Not Started') {
      const everySubRemediationNotStarted = remediationQuestionAnswers.every(rqa => !!rqa.remediationPlan);
      if ((everySubRemediationNotStarted && remediationQuestionAnswers.length > 0) || !!vendorStatus) return false;
    } else {
      const matchingSubRemediations = remediationQuestionAnswers.filter(rqa => filteredPlanStatus === rqa.remediationPlan) || [];
      if (matchingSubRemediations.length === 0 && (!vendorStatus || filteredPlanStatus !== vendorStatus)) {
        return false;
      }
    }
  }

  // Filter by Validation Status
  if (filteredVerificationStatus) {
    const isQuestionInvalidForVerification = !bpVerificationList?.some(bp => bp.bestpracticeId === (question.bestPractice as BestPractice).id);
    const hasMatchingSubRemediation = remediationQuestionAnswers.some(rqa => filteredVerificationStatus === rqa.verificationStatus) && !isQuestionInvalidForVerification;
    const isInvalidVendorStatus = filteredVerificationStatus === 'not_started' && vendorStatus !== REMEDIATION_OPTIONS.remediated;
    const isInvalidMatch = filteredVerificationStatus !== 'not_started' && (!verificationStatus || filteredVerificationStatus !== verificationStatus);
    if (!hasMatchingSubRemediation && (isQuestionInvalidForVerification || isInvalidVendorStatus || isInvalidMatch || vendorStatus !== REMEDIATION_OPTIONS.remediated)) return false;
  }

  // Filter by Past Due
  if (filteredPastDue) {
    const now = moment();
    const hasPastDueSubRemediation = remediationQuestionAnswers.some(rqa => rqa.remediationPlan === REMEDIATION_OPTIONS.willRemediateLater && rqa.remediationDate && moment(rqa.remediationDate).isBefore(now));
    if (
      !hasPastDueSubRemediation &&
            filteredPastDue &&
            status === 'remediation' &&
            vendorStatus === REMEDIATION_OPTIONS.willRemediateLater &&
            remediationDate &&
            moment(remediationDate).isBefore(now)
    ) {
      return false;
    }
  }

  return true;
};