import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../app/hooks';
import { Avatar, Badge, Divider, Stack, Tooltip } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import FileUploaderComp, { FileUpload } from '../Forms/FileUpload';
import { AttachFile } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { AssessmentSurvey, Comment } from '../../interfaces/assessment.interface';
import User from '../../interfaces/users.interface';
import { File as FileTPN } from '../../interfaces/questionAnswer.interface';
import { createMultipleFiles } from '../../services/questionAnswer';
import { appTheme } from '../Themes/light';
import FileViewer from '../FileViewer/FileViewer';

const isUrlImage = ( url:string ) => {
  const extension = url.split(/[#?]/)[0].split('.').pop()?.trim();
  if (!extension) return;
  return ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'].indexOf(extension) > -1;
};

const getFileNamefromURL = ( url:string ) => {
  const filename = url.split(/[#?]/)[0].split('/').pop()?.trim();
  return filename ? filename : '';
};

export default function Comments(props: { disabled?: boolean, comments?: Comment[], handleClose: any, postComment: any, assessmentSurvey?: AssessmentSurvey }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm();

  const [comments, setComments] = useState(props.comments);
  const { user } = useAppSelector(state => state.user);
  const { whiteListedFileTypes } = useAppSelector(state => state.fileSlice);
  const commentsRef = useRef<HTMLDivElement  | null>(null);

  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const handleUploadDocumentOpen = () => setUploadDocumentOpen(true);
  const handleUploadDocumentClose = () => setUploadDocumentOpen(false);
  const [files, setFiles] = useState<FileUpload[]>([]);

  useEffect(() => {
    if (!props.comments) return;
    setComments(props.comments);
  }, [props.comments]);

  useEffect(() => {
    commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [comments]);

  const onSubmit = async (data: FieldValues) => {
    clearErrors();
    if (!data.text || data.text.length === 0) {
      setError('text', { type: 'custom', message: 'This field is required.' });
      return;
    }
    const filesResponse = files && files.length ? await createMultipleFiles(files.map(file =>( file.file as File))) : undefined;
    if (filesResponse && filesResponse.length === 0) {
      setError('text', { type: 'custom', message: 'Error uploading files. Please try again.' });
      return;
    }
    props.postComment(data.text, filesResponse && filesResponse.length > 0 ? filesResponse.map(file => file.id) : []);
    reset();
    setFiles([]);
  };

  const userType = (type?:string) => {
    if (!type) return { bgColor: appTheme.palette.primary.main, color: 'white', typeLabel: type };
    if (type === 'vendor_admin') return { bgColor: appTheme.palette.success.main, color: 'white', typeLabel: 'Service Provider' };
    if (type === 'assessor') return { bgColor: appTheme.palette.assessorApproved.main, color: 'white', typeLabel: 'Assessor' };
    if (type === 'content_owner') return { bgColor: appTheme.palette.secondary.main, color: 'white', typeLabel: 'Content Owner' };
    if (type === 'tpn_admin') return { bgColor: appTheme.palette.warning.main, color: 'black', typeLabel: 'TPN Admin' };
    return { bgColor: appTheme.palette.primary.main, color: 'white', typeLabel: type };
  };

  const CommentCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'comment',
  })<{ comment: Comment }>(({ theme, comment }) => ({
    alignSelf: user && user.id === (comment.user as User).id ? 'flex-end' : 'flex-start',
    backgroundColor: userType((comment.user as User).type).bgColor,
    color: userType((comment.user as User).type).color,
    maxWidth: '75%',
    margin: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    position: 'relative',
    overflow: 'visible',
    '& .MuiCardHeader-content': {
      width: '100%',
    },
  }));

  const CommentAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'comment',
  })<{ comment: Comment }>(({ comment }) => ({
    position: 'absolute',
    top: 0,
    left: user && user.id === (comment.user as User).id ? '100%' : 0,
    transform: 'translate(-50%, -50%)',
  }));

  const textValue = watch('text', '');
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const setFileForPreview = (file: string) => () => {
    setPreviewFile(file);
    setShowFileViewer(true);
  };

  const handleCloseFileViewer = () => {
    setPreviewFile('');
    setShowFileViewer(false);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    setValue('text', pastedData);
  };

  const isValidFileType = useCallback((name: string) => {
    const extension = name.split('.').pop()?.split('?')[0].toLowerCase(); // Extract and normalize file extension
    return extension && whiteListedFileTypes ? whiteListedFileTypes.includes(extension) : false;
  }, [whiteListedFileTypes]);

  return (
    <Box>
      <Box sx={{ width: '100%', maxHeight: '750px', overflow: 'auto', mb: comments && comments.length > 0 ? 2 : 0, pt: comments && comments.length > 0 ? 2 : 0 }}>
         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {comments && comments.sort((a, b) => a.createdAt!.localeCompare(b.createdAt!)).map((comment, idx) => ((user?.type !== 'content_owner' || (props.assessmentSurvey && props.assessmentSurvey.reportIssueDate && comment.createdAt && props.assessmentSurvey.reportIssueDate < comment.createdAt)) &&
          <CommentCard key={comment.id ? comment.id : idx} comment={comment}>
            <CardHeader
              sx={{ p: 0, width: '100%' }}
              titleTypographyProps={{ sx: { display: 'block', overflowWrap: 'break-word', fontSize: '1.2rem' } }}
              title={comment.text}
              subheaderTypographyProps={{ sx: { textAlign: user && user.id === (comment.user as User).id ? 'right' : 'left', fontSize: '.8rem', color: 'rgba(255, 255, 255, 0.5)' } }}
              subheader={`${(comment.user as User).firstName} ${(comment.user as User).lastName} | ${userType((comment.user as User).type).typeLabel} | ${moment(comment.createdAt).format('MM/DD/YYYY HH:mm')}`}
            />
            {comment.attachments && comment.attachments.length > 0 && <>
              <Divider color='white' sx={{ margin: '.5em 0' }} />
              <Stack direction='row' spacing={1}>
                {(comment.attachments as FileTPN[]).map((attachment) => (
                  <Tooltip title={getFileNamefromURL(attachment.document)} key={attachment.id}>
                  { isValidFileType(attachment.document) ? (
                       <div onClick={setFileForPreview(attachment.document)}>{isUrlImage(attachment.document) ? <img src={attachment.document} style={{ maxHeight: '25px' }} /> : <DescriptionIcon fontSize='large' />}</div>
                  ) : (
                      <a href={attachment.document} style={{ color: 'black' }} target="_blank" rel="noreferrer">{isUrlImage(attachment.document) ? <img src={attachment.document} style={{ maxHeight: '25px' }} /> : <DescriptionIcon fontSize='large' />}</a>
                  )}
                  </Tooltip>
                ))}
              </Stack>
              </>}
            <CommentAvatar comment={comment}>{(comment.user as User).firstName.charAt(0)}{(comment.user as User).lastName.charAt(0)}</CommentAvatar>
          </CommentCard>
        ))}
</Box>
         <div ref={commentsRef} />
       </Box>
	       <Box
         sx={{
           width: '100%',
           display: 'flex',
           flexDirection: 'column',
           gap: 1,
         }}
         component="form"
         method="post"
         onSubmit={handleSubmit(onSubmit)}
       >
         <Box sx={{ display: 'flex', alignItems: 'top', position: 'relative' }}>
           <Badge
             sx={{ mr: 1, cursor: 'pointer' }}
             badgeContent={files.length}
             color="primary"
             onClick={handleUploadDocumentOpen}
             anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
           >
              <Avatar>
                <AttachFile />
              </Avatar>
            </Badge>
            <TextField
              error={!!errors.text}
              helperText={!!errors.text && errors.text.message}
              multiline
              minRows={1}
              maxRows={5}
              required
              id='text'
              label='New Comment'
              sx={{ width: '100%', flexGrow: 1 }}
              autoFocus
              {...register('text')}
              onPaste={handlePaste}
              disabled={props.disabled}
            />
          </Box>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
           <Button type="submit" variant="contained" disabled={!textValue?.trim()}>Save</Button>
           <Button variant="outlined" onClick={props.handleClose}>Close</Button>
        </Box>
      </Box>
      <StandardDialog title="Upload Attachment" handleClose={handleUploadDocumentClose} isOpen={uploadDocumentOpen}>
        <FileUploaderComp fileList={files} callback={setFiles} />
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleUploadDocumentClose()}>Close</Button>
        </StandardDialogActions>
      </StandardDialog>
      <FileViewer previewFile={previewFile} showFileViewer={showFileViewer} closeFileViewer={handleCloseFileViewer}/>
    </Box>
  );
}