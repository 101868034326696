import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import { MainListItems } from './NavigationListItems';
import MuiDrawer from '@mui/material/Drawer';
import NestedAccountList from './AccountSubMenu';

import { styled, Theme, CSSObject } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { logoutUser } from '../../app/store/userSlice';
import { fetchWhiteListedFileTypes } from '../../app/store/fileSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import StandardDialog, { StandardDialogActions } from '../../features/Modals/StandardDialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { setShowTokenExpiredModal } from '../../app/store/userSlice';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1),
  marginBotom: 4,
  cursor: 'pointer',
  //Small hack to allow click
  position: 'relative',
  zIndex: 2,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'relative',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function SideNav() {
  const showTokenExpiredModal = useAppSelector(
    (state) => state.user?.showTokenExpiredModal ?? false,
  );
  const [open, setOpen] = React.useState(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const logOutUser = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
    dispatch(setShowTokenExpiredModal(false));
  };

  useEffect(() => {
    dispatch(fetchWhiteListedFileTypes());

    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      if (event !== 'tokenRefresh_failure') return;
      dispatch(setShowTokenExpiredModal(true));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const TPNLogo = `${process.env.PUBLIC_URL}/assets/TPN_logo.png`;
  const TPNLogoSmall = `${process.env.PUBLIC_URL}/assets/TPN_logo_small.png`;

  return (
    <Drawer variant="permanent" open={open}>
      <Box sx={{
        position: 'absolute',
        left: '-429px',
        top: '-546px',
        width: '716px',
        height: '823px',
        background: 'radial-gradient(42.78% 42.78% at 50% 57.22%, #8AA2C5 5.06%, rgba(100, 140, 200, 0.68) 43.23%, rgba(146, 168, 200, 0) 100%)',
        opacity: '0.5',
      }} />
      <Box sx={{ backgroundColor: '#2F2D4F', color: 'white' }}>
        <DrawerHeader onClick={open ? handleDrawerClose : handleDrawerOpen} sx={{ marginTop: (open ? '-30px' : '-10px') }}>
          <Box component='img' src={open ? TPNLogo : TPNLogoSmall} sx={{ maxHeight: (open ? '127px' : '60px'), ml: (open ? '0' : '-10px') }} alt='TPN Logo' />
          <IconButton>
            {open  ? <ChevronLeftIcon sx={{ color: 'white' }} /> : <ChevronRightIcon sx={{ color: 'white' }} />}
          </IconButton>
        </DrawerHeader>
        <List component='nav' sx={{ height: (open ? 'calc(100vh - 83px)' : 'calc(100vh - 36px)'), padding: '10px', mt: '-30px', zIndex: '2' }}>
          <MainListItems open={open} />
          <NestedAccountList />
        </List>
      </Box>
      <StandardDialog cantClose={true} title="Your Login Has Expired" handleClose={() => { return; }} isOpen={showTokenExpiredModal}>
        <Typography>Your login has expired. Please click the button below to login again.</Typography>
        <StandardDialogActions>
          <Button variant="contained" onClick={() => logOutUser()}>Take me to the Login Form</Button>
        </StandardDialogActions>
      </StandardDialog>
    </Drawer>
  );
}
