import React from 'react';
import { Box, InputLabel, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

type TooltipWithLabelProps = {
  label: string;
  tooltipText: React.ReactNode;
};

const TooltipWithLabel = ({ label, tooltipText }: TooltipWithLabelProps) => (
  <Box display="flex" alignItems="center">
    <InputLabel sx={{ fontSize: '0.85rem', display: 'flex', alignItems: 'center' }}>
      {label}
    </InputLabel>
    <Tooltip
      placement="top"
      title={
        <Box sx={{ padding: '5px' }}>
          <Typography variant="caption" fontWeight={500}>
            {tooltipText}
          </Typography>
        </Box>
      }
      arrow
    >
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '-3px' }}>
        <InfoOutlined fontSize="small" style={{ color: 'darkgrey' }} />
      </div>
    </Tooltip>
  </Box>
);

export default TooltipWithLabel;