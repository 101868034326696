import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

const PiDetailsControlComment = (
  { handleSetControlComment, showPiDetailsControlComment, piComment, canEdit } :
  { handleSetControlComment: (val: string | null) => void, canEdit: boolean, showPiDetailsControlComment: boolean, piComment?: string }) => {

  const [comment, setComment] = useState(piComment || '');

  useEffect(() => {
    setComment(piComment || '');
  }, [piComment, showPiDetailsControlComment]);

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleClose = () => {
    handleSetControlComment(null);
    setComment('');
  };

  const handleSave = () => {
    handleSetControlComment(comment);
    setComment('');
  };

  return (
    <StandardDialog
      maxWidth="sm"
      handleClose={handleClose}
      isOpen={showPiDetailsControlComment}
      title="Partially Implemented Control Comment">
      <TextField
        id="assessor-control-comment"
        multiline
        rows={5}
        label="Comment"
        fullWidth
        disabled={!canEdit}
        onChange={handleCommentChange}
        value={comment}
        sx={{ mt: 2 }}
        inputProps={{ readOnly: false, sx: { overflowY: 'scroll' } }}
      />
      <StandardDialogActions>
        {canEdit && (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleSave}>
            Continue
          </Button>
        )}
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined">
          {canEdit ? 'Cancel' : 'Close'}
        </Button>
      </StandardDialogActions>
    </StandardDialog>
  );
};

export default PiDetailsControlComment;