import React, { useState, useEffect, useMemo } from 'react';
import {
  Popover, Box, Typography, Divider, List, ListItem, Tabs, Tab,
} from '@mui/material';
import { Notification } from '../../interfaces/assessment.interface';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { calculateDaysAgo } from '../../utils/notificationUtils';
import { ADMIN_REMEDIATION_VALIDATION, NOTIFICATION_TYPES, REMEDIATION_PLAN_OVERDUE, REMEDIATION_VALIDATION_STATUS_UPDATE } from '../../services/surveyHelpers';

const RenderNotification: React.FC<{
  notification: Notification;
  index: number;
  handleBPClick: (bp: any, notificationId: number) => void;
}> = ({ notification, index, handleBPClick }) => {
  const theme = useTheme();
  const today = new Date();
  const notificationDate = new Date(notification.date);
  const diffInMs = today.getTime() - notificationDate.getTime();
  const daysAgo = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hoursAgo = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutesAgo = Math.floor(diffInMs / (1000 * 60));

  let displayTime;
  if (daysAgo > 0) {
    displayTime = `${daysAgo} Day${daysAgo > 1 ? 's' : ''} Ago`;
  } else if (hoursAgo > 0) {
    displayTime = `${hoursAgo} Hour${hoursAgo > 1 ? 's' : ''} Ago`;
  } else {
    displayTime = `${minutesAgo} Minute${minutesAgo > 1 ? 's' : ''} Ago`;
  }

  const newVerificationStatus = useMemo(() => {
    if (notification?.type !== NOTIFICATION_TYPES[REMEDIATION_VALIDATION_STATUS_UPDATE]) return null;

    if (notification?.message.includes('reviewed') || notification?.message.includes('pass')) return 'Reviewed';
    if (notification?.message.includes('rejected') || notification?.message.includes('fail')) return 'Rejected';

    return null;
  }, [notification]);

  return (
    <ListItem
      key={index}
      onClick={() => notification.bestPractice && handleBPClick(notification.bestPractice, notification.id)}
      sx={{
        '&:hover': { backgroundColor: '#F1F4FA' },
        px: 3, py: 1, my: 1, borderRadius: '8px', transition: 'background-color 0.2s ease',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}>
        <FiberManualRecordIcon
          sx={{ color: notification.read ? 'transparent' : theme.palette.primary.main, fontSize: '14px', ml: 1 }}
        />
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{ fontSize: '15px', fontWeight: '500', color: theme.palette.text.primary }}
            >
          {notification.type}
          {!!newVerificationStatus && (
            <Typography
              component="span"
              sx={{
                fontWeight: newVerificationStatus === 'Reviewed' ? 'bold' : '500',
                color: newVerificationStatus === 'Rejected'
                  ? theme.palette.error.main
                  : newVerificationStatus === 'Reviewed'
                    ? theme.palette.success.main
                    : theme.palette.text.primary,
                ml: 0.5,
              }}
            >
              - {newVerificationStatus}
            </Typography>
          )}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ fontSize: '13px', fontWeight: 500, color: '#AEAEB1', ml: 1 }}
              >
                {displayTime}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: '14px', mt: 0.5, color: theme.palette.customThemeColors?.darkGrey, mr: 1 }}
          >
            <strong>{notification.shortCode} {notification?.bestPractice?.title}:</strong> {notification.questionTitle}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

export default function NotificationPopover({
  anchorEl,
  open,
  onClose,
  notifications,
  handleBPClick,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  notifications: Notification[];
  handleBPClick: (bp: any, notificationId: number) => void;
}) {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const { user } = useAppSelector(state => state.user);

  useEffect(() => {
    setSelectedTab(0);
  }, [open]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const filteredNotifications = notifications.filter(notification => {
    // Never show Reviewed status notifications to non TPN Admins or COs
    if (notification.type === NOTIFICATION_TYPES[REMEDIATION_VALIDATION_STATUS_UPDATE] &&
      (notification?.message.includes('reviewed') || notification?.message.includes('pass')) &&
      !['tpn_admin', 'content_owner'].includes(user?.type || '')) {
      return false;
    }

    if (selectedTab === 0) {
      return true;
    }

    const tab = user?.type === 'tpn_admin' ? selectedTab : selectedTab + 1;
    if (tab === 1) {
      return notification.type === NOTIFICATION_TYPES[ADMIN_REMEDIATION_VALIDATION];
    } else if (tab === 2) {
      return notification.type === NOTIFICATION_TYPES[REMEDIATION_PLAN_OVERDUE];
    } else if (tab === 3) {
      return notification.type === NOTIFICATION_TYPES[REMEDIATION_VALIDATION_STATUS_UPDATE];
    }
  });

  const todayNotifications = filteredNotifications.filter(notification => calculateDaysAgo(new Date(notification.date)) === 0);
  const yesterdayNotifications = filteredNotifications.filter(notification => calculateDaysAgo(new Date(notification.date)) === 1);
  const olderNotifications = filteredNotifications.filter(notification => calculateDaysAgo(new Date(notification.date)) > 1);

  const sharedTabStyle = () => ({
    fontWeight: 400,
    fontSize: '15px',
    minHeight: '36px',
    padding: '0 12px',
    textTransform: 'none',
    color: theme.palette.customThemeColors?.darkGrey,
  });

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { 
          width: 769,
          maxHeight: 650,
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ p: 0 }}>
        <Typography variant="h5" sx={{ mb: 1, px: 2, pt: 2, fontWeight: 400, color: theme.palette.customThemeColors?.darkGrey }}>
          Remediation Dashboard
        </Typography>

        {/* Tabs for filtering */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Notification Filter Tabs"
          sx={{
            minHeight: '36px',
            width: 'auto',
            marginLeft: '16px',
          }}
        >
          <Tab
            label="All"
            sx={{ ...sharedTabStyle }}
          />
          { user?.type === 'tpn_admin' && (
            <Tab
              label="Admin"
              sx={{ ...sharedTabStyle }}
            />
          )}
          <Tab
            label="Past-Due Remediations"
            sx={{ ...sharedTabStyle }}
          />
          <Tab
            label="Remediation Validation"
            sx={{ ...sharedTabStyle }}
          />
        </Tabs>
        <Divider sx={{ p: 0, mb: 2 }} />
        {filteredNotifications.length === 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                px: 3,
                pb: 2,
                textAlign: 'center',
                fontWeight: 600,
                color: 'black',
              }}
            >
              You&apos;re up to date!
            </Typography>

            <Typography
              variant="body2"
              sx={{
                px: 3,
                pb: 4,
                textAlign: 'center',
                fontWeight: 400,
                color: theme.palette.customThemeColors?.darkGrey,
              }}
            >
              We&apos;ll keep you posted with important changes to your assessment.
            </Typography>
          </>
        )}

        <Box sx={{ maxHeight: 450, overflowY: 'auto', outline: 'none' }} tabIndex={0}>
          <List sx={{ p: 0, m: 0 }}>
            {todayNotifications.length > 0 && (
              <>
                <Typography
                sx={{
                  pt: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  px: 2,
                  color: theme.palette.customThemeColors?.darkGrey,
                }}
              >
                Today
              </Typography>
                {todayNotifications.map((notification, index) => (
                  <RenderNotification
                    key={notification.id || index}
                    notification={notification}
                    index={index}
                    handleBPClick={handleBPClick}
                  />
                ))}
              </>
            )}
            {yesterdayNotifications.length > 0 && (
              <>
               <Typography
                sx={{
                  pt: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  px: 2,
                  color: theme.palette.customThemeColors?.darkGrey,
                }}
              >
                Yesterday
              </Typography>
                {yesterdayNotifications.map((notification, index) => (
                  <RenderNotification
                    key={notification.id || index}
                    notification={notification}
                    index={index}
                    handleBPClick={handleBPClick}
                  />
                ))}
              </>
            )}
            {olderNotifications.length > 0 && (
              <>
                <Typography
                  sx={{
                    pt: 1,
                    fontSize: '18px',
                    fontWeight: 400,
                    px: 2,
                    color: theme.palette.customThemeColors?.darkGrey,
                  }}
                >Older Remediation Notifications
                </Typography>
                {olderNotifications.map((notification, index) => (
                  <RenderNotification
                    key={notification.id || index}
                    notification={notification}
                    index={index}
                    handleBPClick={handleBPClick}
                  />
                ))}
              </>
            )}
          </List>
        </Box>
      </Box>
    </Popover>
  );
}