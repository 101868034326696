import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubRemediation } from '../../../interfaces/assessment.interface';
import { FileUpload } from '../../Forms/FileUpload';
import moment from 'moment';
import { REMEDIATION_OPTIONS } from '../../../services/surveyHelpers';

const StepRemediationStatusIndicator = ({ subRemediations, subRemVFiles }: { subRemediations: SubRemediation[], subRemVFiles: { [key: string]: FileUpload[] } }) => {
  const stepsRemaining: string[] = subRemediations.reduce((acc: string[], subRem, index) => {
    const fileAtIndex = subRemVFiles[subRem.answer as keyof typeof subRemVFiles];
    const invalidFileAtIndex = fileAtIndex && fileAtIndex.find((file) => file.isPublic === '');
    if (subRem.remediationPlan &&
      (subRem.willNotRemediateReason || [REMEDIATION_OPTIONS.remediated, REMEDIATION_OPTIONS.willRemediateLater].includes(subRem.remediationPlan)) &&
      subRem.remediationDate && subRem.remediationComment && !invalidFileAtIndex) return acc;
    let stepString = `Remediation ${index + 1} requires`;
    if (!subRem.remediationPlan) {
      stepString += ' a Remediation Plan';
    }
    if (subRem.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate && !subRem.willNotRemediateReason) {
      stepString += `${!subRem.remediationPlan ? ' and' : ''} a Reason for Not Remediating`;
    }
    if (!subRem.remediationDate || !moment(subRem.remediationDate).isValid()) {
      stepString += `${subRem.remediationPlan ? ' and' : ''} a Remediation Date`;
    }
    if (!subRem.remediationComment) {
      stepString += `${!subRem.remediationPlan || !subRem.remediationDate ? ' and' : ''} Additional Details`;
    }
    if (invalidFileAtIndex?.file) {
      stepString += `${!subRem.remediationPlan || !subRem.remediationDate || !subRem.remediationComment ? ' and' : ''} a CO Visibility value on all evidence files`;
    }
    acc.push(stepString);
    return acc;
  }, []);

  const isComplete = stepsRemaining.length === 0;

  return (
    <Tooltip
      title={
        <Box sx={{ padding: 1 }}>
          <Typography variant="h6" gutterBottom>
            Status: {isComplete ? 'Complete' : 'Incomplete'}
          </Typography>
          {!isComplete && stepsRemaining.map((step, index) => (
            <Typography key={index} variant="body2">
              {step}
            </Typography>
          ))}
        </Box>
      }
      arrow
      placement="top"
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          width: '25px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '25px',
            width: '25px',
            borderRadius: '50%',
            backgroundColor: isComplete
              ? 'rgba(129, 199, 132, 0.2)'
              : 'rgba(255, 183, 77, 0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          {isComplete ? (
            <CheckCircleIcon sx={{ fontSize: '18px' }} color="success" />
          ) : (
            <PriorityHighIcon sx={{ fontSize: '18px' }} color="error" />
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default StepRemediationStatusIndicator;