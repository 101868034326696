import React, { useCallback, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Badge,
  IconButton,
} from '@mui/material';
import StandardDialog from '../Modals/StandardDialog';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { remediationFilter } from '../../services/filters';
import { setBestPractice, setQuestion } from '../../app/store/questionnaireAnswerSlice';
import {
  setVerificationStatus,
  setPlanStatus,
  setPastDue,
  setFilteredQuestionType,
  clearFilters,
  QuestionTypes,
  VerificationStatus,
  PlanStatus,
} from '../../app/store/remediationFilterSlice';
import { FULLY_IMPLEMENTED, NOT_APPLICABLE } from '../../services/surveyHelpers';
import { REMEDIATION_OPTIONS } from '../../services/surveyHelpers';

export default function FilterMenu() {
  const { user } = useAppSelector((state) => state.user);

  // Dynamic Filter Options based on User Type
  const filterOptions = useMemo(() => ({
    'Question Type': [
      { label: 'Best Practice', value: 'Best Practice' },
      { label: 'Additional Recommendation', value: 'Additional Recommendation' },
    ],
    'Plan Status': [
      { label: 'Remediated', value: REMEDIATION_OPTIONS.remediated },
      { label: 'Will Remediate Later', value: REMEDIATION_OPTIONS.willRemediateLater },
      { label: 'Will not Remediate', value: REMEDIATION_OPTIONS.willNotRemediate },
      { label: 'Not Started', value: 'Not Started' },
    ],
    'Validation Status':
    ['content_owner', 'tpn_admin'].includes(user?.type || '') ?
      [
        { value: 'reviewed', label: 'Reviewed' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'not_started', label: 'Not Started' },
      ] : [
        { value: 'rejected', label: 'Rejected' },
      ],
    'Plan Due Date': [{ label: 'Past Due', value: 'Past Due' }],
  }), [user]);

  const { filteredVerificationStatus, filteredPlanStatus, filteredPastDue, filteredQuestionType } =
    useAppSelector((state) => state.remediationFilterSlice);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { bpVerificationList } = useAppSelector((state) => state.assessments);
  const { questionnaire, bpStatus, assessmentQuestionsStatus } = useAppSelector(state => state.questionnaireAnswer);

  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    questionType: filteredQuestionType || null,
    filteredVerificationStatus: filteredVerificationStatus || null,
    filteredPlanStatus: filteredPlanStatus || null,
    filteredPastDue: filteredPastDue || null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setTempSelectedFilters({
      questionType: filteredQuestionType || null,
      filteredVerificationStatus: filteredVerificationStatus || null,
      filteredPlanStatus: filteredPlanStatus || null,
      filteredPastDue: filteredPastDue || null,
    });
    setIsModalOpen(!isModalOpen);
  };

  const setNextFilteredQuestion = useCallback((
    filteredQuestionTypeParam: QuestionTypes,
    filteredVerificationStatusParam: VerificationStatus,
    filteredPlanStatusParam: PlanStatus,
    filteredPastDueParam: boolean | null,
  ) => {
    if (!questionnaire || !bpStatus || !Object.keys(bpStatus).length) return;

    for (const domain of questionnaire.domains) {
      for (const topic of domain.topics) {
        for (const bp of topic.bestPractices) {
          if (bpStatus[bp.id].status === 'hide' || bpStatus[bp.id].visibleQuestionsCount === 0) continue;

          for (const question of bp.questions) {
            const questionStatus = assessmentQuestionsStatus?.[question.id];
            if (!questionStatus || [FULLY_IMPLEMENTED, NOT_APPLICABLE].includes(questionStatus.answer || '')) continue;
            const isValid = remediationFilter(
              filteredVerificationStatusParam,
              filteredQuestionTypeParam,
              filteredPlanStatusParam,
              filteredPastDueParam,
              question,
              questionStatus,
              bpVerificationList,
            );

            if (isValid) {
              dispatch(setBestPractice(bp));
              dispatch(setQuestion(question));
              return;  // Stop once the first matching question is found
            }
          }
        }
      }
    }
  }, [questionnaire, bpStatus, assessmentQuestionsStatus, bpVerificationList]);

  const toggleFilterOption = (category: string, option: { value: string, label: string }) => {
    setTempSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (category === 'Question Type') {
        newFilters.questionType = prevFilters.questionType === option.value ? null : (option.value as QuestionTypes);
      } else if (category === 'Validation Status') {
        const isSelected = prevFilters.filteredVerificationStatus === option.value;
        newFilters.filteredVerificationStatus = isSelected ? null : (option.value as VerificationStatus);

        // If any Validation Status is selected, ensure Plan Status is "Remediated"
        if (!isSelected) {
          newFilters.filteredPlanStatus = REMEDIATION_OPTIONS.remediated;

          // Deselect "Past Due" if Validation Status is selected
          newFilters.filteredPastDue = null;
        }
      } else if (category === 'Plan Status') {
        const isSelected = prevFilters.filteredPlanStatus === option.value;
        newFilters.filteredPlanStatus = isSelected ? null : (option.value as PlanStatus);

        // If a Plan Status other than "Will Remediate Later" is selected, deselect "Past Due"
        if (newFilters.filteredPlanStatus !== REMEDIATION_OPTIONS.willRemediateLater) {
          newFilters.filteredPastDue = null;
        }

        // Clear Validation Status if Plan Status is not "Remediated"
        if (newFilters.filteredPlanStatus !== REMEDIATION_OPTIONS.remediated) {
          newFilters.filteredVerificationStatus = null;
        }
      } else if (category === 'Plan Due Date') {
        if (option.value === 'Past Due') {
          const isSelected = prevFilters.filteredPastDue === true;
          newFilters.filteredPastDue = isSelected ? null : true;

          // Automatically set "Plan Status" to "Will Remediate Later" if "Past Due" is selected
          if (!isSelected) {
            newFilters.filteredPlanStatus = REMEDIATION_OPTIONS.willRemediateLater;

            // Deselect "Validation Status" if "Past Due" is selected
            newFilters.filteredVerificationStatus = null;
          }
        }
      }

      return newFilters;
    });
  };

  const clearAllFilters = () => {
    setTempSelectedFilters({
      questionType: null,
      filteredVerificationStatus: null,
      filteredPlanStatus: null,
      filteredPastDue: null,
    });
    dispatch(clearFilters());
    setIsModalOpen(false);
  };

  const applyFilters = () => {
    dispatch(setFilteredQuestionType(tempSelectedFilters.questionType));
    dispatch(setVerificationStatus(tempSelectedFilters.filteredVerificationStatus));
    dispatch(setPlanStatus(tempSelectedFilters.filteredPlanStatus));
    dispatch(setPastDue(tempSelectedFilters.filteredPastDue));
    setNextFilteredQuestion(tempSelectedFilters.questionType, tempSelectedFilters.filteredVerificationStatus, tempSelectedFilters.filteredPlanStatus, tempSelectedFilters.filteredPastDue);

    setIsModalOpen(false);
  };

  const isOptionSelected = (category: string, option: { value: string, label: string }) => {
    if (category === 'Question Type') {
      return tempSelectedFilters.questionType === option.value;
    }
    if (category === 'Validation Status') {
      return tempSelectedFilters.filteredVerificationStatus === option.value;
    }
    if (category === 'Plan Status') {
      return tempSelectedFilters.filteredPlanStatus === option.value;
    }
    if (category === 'Plan Due Date') {
      return option.value === 'Past Due' && tempSelectedFilters.filteredPastDue === true;
    }
    return false;
  };

  const appliedFiltersCount = [
    filteredQuestionType,
    filteredVerificationStatus,
    filteredPlanStatus,
    filteredPastDue,
  ].filter(Boolean).length;

  const generateFilterSummary = () => {
    const conditions = [];

    // Question Type Filter
    if (tempSelectedFilters.questionType) {
      conditions.push(
        <Typography component="span" variant="body2" key="questionType">
          Question Type is{' '}
          <Typography component="span" variant="body2" fontWeight="bold">
            {tempSelectedFilters.questionType}
          </Typography>
        </Typography>,
      );
    }

    // Plan Status Filter
    if (tempSelectedFilters.filteredPlanStatus) {
      conditions.push(
        <Typography component="span" variant="body2" key="planStatus">
          Plan Status is{' '}
          <Typography component="span" variant="body2" fontWeight="bold">
          {
            filterOptions['Plan Status'].find(
              (status) => status.value === tempSelectedFilters.filteredPlanStatus,
            )?.label || tempSelectedFilters.filteredPlanStatus
          }
          </Typography>
        </Typography>,
      );
    }

    // Validation Status Filter (Explicit Handling)
    if (tempSelectedFilters.filteredVerificationStatus) {
      const statusMap = {
        reviewed: 'Reviewed',
        rejected: 'Rejected',
        not_started: 'Not Started',
      };

      const validationStatus = statusMap[tempSelectedFilters.filteredVerificationStatus] || 'Unknown';

      conditions.push(
        <Typography component="span" variant="body2" key="validationStatus">
          Validation Status is{' '}
          <Typography component="span" variant="body2" fontWeight="bold">
            {validationStatus}
          </Typography>
        </Typography>,
      );
    }

    // Past Due Filter
    if (tempSelectedFilters.filteredPastDue) {
      conditions.push(
        <Typography component="span" variant="body2" key="pastDue">
          Plan Due Date is{' '}
          <Typography component="span" variant="body2" fontWeight="bold">
            Past Due
          </Typography>
        </Typography>,
      );
    }

    // Combine Conditions
    return conditions.length > 0 ? (
      <Typography variant="body2">
        Show Remediations where{' '}
        {conditions.reduce((prev, curr, index) => (
          <>
            {prev}
            {index > 0 && ' and '}
            {curr}
          </>
        ))}
        .
      </Typography>
    ) : (
      <Typography variant="body2">No filters applied. Showing all Remediations.</Typography>
    );
  };

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Badge
        badgeContent={appliedFiltersCount}
        color="primary"
        invisible={appliedFiltersCount === 0}
      >
        <Button variant="outlined" onClick={toggleModal} startIcon={<TuneIcon />}>
          Filters
        </Button>
      </Badge>

      <StandardDialog
        isOpen={isModalOpen}
        handleClose={toggleModal}
        title={
          <Box display="flex" alignItems="center">
            <TuneIcon sx={{ marginRight: 1 }} />
            Remediation Filters
          </Box>
        }
        maxWidth="lg"
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {Object.entries(filterOptions).map(([category, options]) => (
              <Grid item xs={3} key={category}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    pb: 1,
                    fontWeight: 500,
                    color: theme.palette.customThemeColors?.darkGrey,
                    borderBottom: '1px solid lightgray',
                  }}
                >
                  {category.toUpperCase()}
                </Typography>
                <Grid container direction="column" spacing={1}>
                  {options.map((option) => {
                    const { value, label } =
                      typeof option === 'string' ? { value: option, label: option } : option;

                    return (
                      <Grid
                        item
                        key={value}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          padding: '8px 12px',
                          borderRadius: '6px',
                          transition: 'background-color 0.2s ease-in-out',
                          '&:hover': {
                            backgroundColor: theme.palette.grey[50],
                          },
                          backgroundColor: isOptionSelected(category, option)
                            ? theme.palette.grey[50]
                            : 'transparent',
                        }}
                        onClick={() => toggleFilterOption(category, option)}
                      >
                        <Typography
                          sx={{
                            lineHeight: '30px',
                            fontWeight: isOptionSelected(category, option) ? 500 : 'normal',
                            color: isOptionSelected(category, option)
                              ? theme.palette.text.primary
                              : theme.palette.grey[600] || theme.palette.text.primary,
                          }}
                        >
                          {label}
                        </Typography>
                        {isOptionSelected(category, option) && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleFilterOption(category, option);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ))}
          </Grid>
          {/* Filter Summary */}
          <Box sx={{ mt: 3, p: 2, backgroundColor: theme.palette.grey[50], borderRadius: '6px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {generateFilterSummary()}
            </Typography>
          </Box>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={clearAllFilters} variant="outlined">
              Clear All Filters
            </Button>
            <Button
              variant="contained"
              onClick={applyFilters}
              disabled={
                tempSelectedFilters.questionType === null &&
                tempSelectedFilters.filteredPlanStatus === null &&
                tempSelectedFilters.filteredVerificationStatus === null &&
                tempSelectedFilters.filteredPastDue === null
              }
              sx={{
                animation: () =>
                  !(
                    tempSelectedFilters.questionType === null &&
                    tempSelectedFilters.filteredPlanStatus === null &&
                    tempSelectedFilters.filteredVerificationStatus === null &&
                    tempSelectedFilters.filteredPastDue === null
                  )
                    ? 'pulse 4.5s ease-in-out infinite'
                    : 'none',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.7)',
                  },
                  '20%': {
                    transform: 'scale(1.0)',
                    boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)',
                  },
                  '40%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)',
                  },
                  '100%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)',
                  },
                },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </StandardDialog>
    </Box>
  );
}