import './fileUpload.css';
import React, { useState, useEffect, useCallback } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import moment from 'moment';
import { Box, IconButton, Typography, Button, Select, MenuItem, FormControl } from '@mui/material';
import { Delete, Description } from '@mui/icons-material';
import { useAppSelector } from '../../app/hooks';
import User from '../../interfaces/users.interface';
import { DataCell, DataRow } from '../Tables/DataList';
import StandardDialog from '../Modals/StandardDialog';
import FileViewer from '../FileViewer/FileViewer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTheme } from '@mui/material/styles';

export interface FileUpload {
  id?: number;
  file: File | string;
  date: string;
  user: User
  isPublic: boolean | '';
}

export default function FileUploaderComp(props: { showCOPublicModal?: boolean, fileList?:FileUpload[], callback?: any, multiple?: boolean, hideFileList?: boolean, dontShowUpload?: boolean, boxHeight?: number, enablePublic?: boolean, notAssessorVisible?: boolean }) {
  const theme = useTheme();

  const { user } = useAppSelector(state => state.user);
  const { whiteListedFileTypes } = useAppSelector(state => state.fileSlice);
  const canSeeIsPublic = user?.type === 'vendor_admin' && props.enablePublic;
  const [files, setFiles] = useState(props.fileList ? props.fileList : []);
  const [showPublic, setShowPublic] = useState(false);
  const [showInvalidFileType, setShowInvalidFileType] = useState(false);
  const handleSetFiles = useCallback((nfiles:FileList | File) => {
    if (!user) return;
    if ((nfiles as FileList).length) {
      const fileList:FileUpload[] = Array.from(nfiles as FileList).map(file => { return ({ file, user, date: moment().utc().format('YYYY-MM-DDTHH:mm:ssZZ'), isPublic: '' }); });
      setFiles([ ...files, ...fileList ]);
      if (props.callback) props.callback([ ...files, ...fileList ]);
    } else {
      const fileList:FileUpload[] = [{ file:(nfiles as File), user, date: moment().utc().format('YYYY-MM-DDTHH:mm:ssZZ'), isPublic: '' }];
      if (files.length === 0) {
        setFiles(fileList);
        if (props.callback) props.callback(fileList);
      } else {
        setFiles([ ...files, ...fileList ]);
        if (props.callback) props.callback([ ...files, ...fileList ]);
      }
    }
  }, [files]);

  const handleIsPublicChange = (value: string | boolean, index: number) => {
    if (!(user?.type === 'tpn_admin' || user?.type === 'vendor_admin')) {
      return;
    }

    const newFiles = files.map((file, fileIdx) => {
      if (index === fileIdx) {
        file.isPublic = value === 'true' ? true : false;
      }
      return file;
    });

    setFiles(newFiles);
    if (props.callback) props.callback(newFiles);
  };

  const isFileImage = (file:File) => {
    return file && file?.type?.split('/')[0] === 'image';
  };

  const isUrlImage = ( url:string ) => {
    const extension = url.split(/[#?]/)[0].split('.').pop()?.trim();
    if (!extension) return;
    return ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'].indexOf(extension) > -1;
  };

  const getFileNamefromURL = ( url:string ) => {
    const filename = url.split(/[#?]/)[0].split('/').pop()?.trim();
    return filename;
  };

  useEffect(()=> {
    if (props.fileList) setFiles(props.fileList);
  }, [props.fileList]);

  const deleteFile = (index: number) => {
    const fileList:FileUpload[] = files.filter((file, i) => i !== index);
    setFiles(files.filter((file, i) => i !== index));
    if (props.callback) props.callback(fileList);
  };

  const isValidFileType = useCallback((name: string) => {
    const extension = name.split('.').pop()?.split('?')[0].toLowerCase();
    return extension && whiteListedFileTypes ? whiteListedFileTypes.includes(extension) : false;
  }, [whiteListedFileTypes]);

  const handleSetFilePreload = React.useCallback(async (nFiles: FileList | File) => {
    const filesArray = nFiles instanceof FileList ? Array.from(nFiles) : [nFiles];
    const hasWhiteListFileExtensions = filesArray.every(file => {
      return isValidFileType(file.name);
    });

    if (!hasWhiteListFileExtensions) {
      setShowInvalidFileType(true);
      return;
    }

    if (!filesArray.length) return;
    setShowInvalidFileType(false);
    if (props.showCOPublicModal) setShowPublic(true);
    handleSetFiles(nFiles);
  }, [handleSetFiles, props.showCOPublicModal]);

  const handleSaveFileIsPublicInitValue = useCallback((isPublic:boolean) => {
    if (files) {
      const newFiles = files.map(file => {
        file.isPublic = file.isPublic === '' ? isPublic : file.isPublic;
        return file;
      });
      setFiles(newFiles);
      if (props.callback) props.callback(newFiles);
    }
    setShowPublic(false);
  }, [files]);

  const cellStyle = { fontSize: '15px' };
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const setFileForPreview = (file: string) => () => {
    setPreviewFile(file);
    setShowFileViewer(true);
  };

  const handleCloseFileViewer = () => {
    setPreviewFile('');
    setShowFileViewer(false);
  };

  return (
    <>
    <StandardDialog
      title={'Visible to Content Owners?'}
      subtitle={`Select 'Yes' to allow Content Owners to view this evidence.${!props.notAssessorVisible ? ' Otherwise, it will only be accessible to your Assessor.' : ''}`}
      handleClose={() => setShowPublic(false)}
      maxWidth='sm'
      isOpen={showPublic}
    >
      <Box display="flex" justifyContent="center" gap="10px" width="100%">
        <Button
             onClick={() => handleSaveFileIsPublicInitValue(false)}
            color="primary"
            variant="outlined"
          >
            No
          </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => handleSaveFileIsPublicInitValue(true)}
            >
              Yes
            </Button>
          </Box>
    </StandardDialog>
    <Box>
      {!props.dontShowUpload && <Box>
        <Typography variant='body1' mb={1}>Accepted file types: JPG, PNG, JPEG, GIF, PDF, DOCX, and XLSX. Maximum size: 50MB.</Typography>
        <FileUploader
          multiple={props.multiple !== undefined ? props.multiple : true}
          handleChange={(nfiles:File | FileList) => handleSetFilePreload(nfiles)}
          classes="dropZoneFull"
          hoverTitle="."
        >
          <Box
            sx={{
              width: '100%',
              height: props.boxHeight ? `${props.boxHeight}px` : '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px dashed ${theme.palette.customThemeColors?.lightGray}`,
              position: 'relative',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                textAlign: 'center',
              }}
            >
              Upload or Drop file{props.multiple !== undefined ? (props.multiple ? '(s)' : '') : '(s)'} here
            </Typography>
            {showInvalidFileType && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: props.boxHeight ? `${(props.boxHeight / 2) - 41}px` : '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <WarningAmberIcon sx={{ color: '#B55C5a', mr: 1, fontSize: 20 }} />
                <Typography variant="body1">Invalid file type.</Typography>
              </Box>
            )}          
          </Box>
        </FileUploader>
      </Box>}
      {!props.hideFileList && files.length > 0 &&
        <Box>
          <DataRow header>
            <DataCell sx={cellStyle} xs={2}>Preview</DataCell>
            <DataCell sx={cellStyle} xs={canSeeIsPublic ? 3 : 4}>Name</DataCell>
            <DataCell sx={cellStyle} xs={2}>User</DataCell>
            <DataCell sx={cellStyle} xs={canSeeIsPublic ? 2 : 3}>Uploaded</DataCell>
            {canSeeIsPublic &&
            <DataCell sx={cellStyle} xs={2}>CO Visibility</DataCell>
            }
            <DataCell xs={1}>-</DataCell>
          </DataRow>
          {files.map((file, index) => (
            <DataRow key={index}>
             {typeof file?.file !== 'string' && <>
                 <DataCell xs={2} sx={{ textAlign: 'center' }}>{isFileImage(file?.file) ? <img src={URL.createObjectURL(file.file)} style={{ maxWidth: '100%' }} /> : <Description fontSize='large' />}</DataCell>
                 <DataCell xs={canSeeIsPublic ? 3 : 4}><Typography sx={{ maxWidth: '100%', overflowWrap: 'break-word' }}>{file?.file?.name}</Typography></DataCell>
              </>}
              {typeof file.file === 'string' && <>
                <DataCell xs={2} sx={{ textAlign: 'center' }}>
                {  isValidFileType(file.file) ? (
                  <div onClick={setFileForPreview(file.file)}>{isUrlImage(file.file) ? <img src={file.file} style={{ maxWidth: '100%' }} /> : <Description fontSize='large' />}</div>
                ) : (
                  <a href={file.file} style={{ color: 'black' }} target="_blank" rel="noreferrer">{isUrlImage(file.file) ? <img src={file.file} style={{ maxWidth: '100%' }} /> : <Description fontSize='large' />}</a>
                )}
                </DataCell>
                <DataCell xs={canSeeIsPublic ? 3 : 4}><Typography sx={{ maxWidth: '100%', overflowWrap: 'break-word' }}>{getFileNamefromURL(file.file)}</Typography></DataCell>
              </>}
              <DataCell xs={2}>{file.user?.firstName} {file.user?.lastName}</DataCell>
              <DataCell xs={canSeeIsPublic ? 2 : 3}>{moment(file.date).format('MM/DD/YYYY hh:mm')}</DataCell>
              {canSeeIsPublic && <DataCell xs={2} sx={{ textAlign: 'center' }}>
                <FormControl error={file.isPublic === ''} fullWidth>
                  <Select
                    onChange={(e) => handleIsPublicChange(e.target.value, index)}
                    value={file.isPublic}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Select>
                </FormControl>
              </DataCell>}
              <DataCell xs={1}>{!props.dontShowUpload && <IconButton onClick={() => deleteFile(index)}><Delete /></IconButton>}</DataCell>
            </DataRow>
          ))}
        </Box>
      }
    </Box>
    <FileViewer previewFile={previewFile} showFileViewer={showFileViewer} closeFileViewer={handleCloseFileViewer}/>
    </>
  );
}
