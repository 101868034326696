import React from 'react';
import moment from 'moment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Avatar, Box, Tooltip } from '@mui/material';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import User, { Badge, CompanyCertification } from '../../interfaces/users.interface';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../../app/hooks';
import { Star } from '@mui/icons-material';
import { downloadWithWhiteBackground } from '../../services/badge';

const useStyles = makeStyles(() => ({
  badge: {
    width: '35px',
    height: '35px',
  },
}));

export default function GetBadge(props: { blueBadgeLabel?: string, goldBadgeLabel?: string, badge?: string, assessorLevel?: number, assessmentSurvey?: AssessmentSurvey, certification?: CompanyCertification, assessor?: User, company?: number, isActive?: boolean, shield?: Badge  }) {
  const { user } = useAppSelector(state => state.user);
  const classes = useStyles();
  const badge = props.shield?.title || props.badge;
  let icon = <Box className={classes.badge}></Box>;
  if (props.assessorLevel) {
    icon = (
      <Tooltip title={`Assessor Level ${props.assessorLevel}`}>
        <Box sx={{ position: 'relative' }}>
          <Star color="primary" fontSize='large' sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
          <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '.8em' }}>{props.assessorLevel}</Box>
        </Box>
      </Tooltip>
    );
  }
  if (!props.assessorLevel && badge == 'non_tpn_self_reported') {
    if (props.certification?.document) {
      icon = <a href={props.certification ? props.certification.document : '#'} target="_blank" rel="noreferrer"><Tooltip title={props.certification ? `${props.certification.certification.title} issued on ${moment(props.certification.issueDate).format('MM/DD/YYYY')}` : 'Certification Uploaded'}><ReceiptLongIcon fontSize='large' /></Tooltip></a>;
    } else {
      icon = <Tooltip title={(user?.type === 'tpn_admin' || user?.type === 'content_owner') ? (`Certification Uploaded${props.shield ? `: Expires ${moment(props.shield?.expirationDate).format('MM/DD/YYYY')}` : ''}`) : ''}><ReceiptLongIcon fontSize='large' /></Tooltip>;
    }
  }

  if (!props.assessorLevel && badge == 'tpn_self_reported') icon = <Tooltip title={(user?.type === 'tpn_admin' || user?.type === 'content_owner') ? (`Blue ${props.shield ? `- Expires ${moment(props.shield?.expirationDate).format('MMM D, YYYY')}` : ''}`) : ''}><Box component='img' src={`${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`} className={classes.badge} /></Tooltip>;
  if (!props.assessorLevel && badge == 'tpn_assessed') icon = <Tooltip title={(user?.type === 'tpn_admin' || user?.type === 'content_owner') ? (`Gold ${props.assessmentSurvey ? ` on ${moment(
    props.assessmentSurvey.reportIssueDate).format('MMM D, YYYY')} by ${props.assessor?.firstName} ${props.assessor?.lastName}` : ''}${props.shield  ? ` - Expires ${moment(props.shield?.expirationDate).format('MMM D, YYYY')}` : ''}`) : ''}><Box component='img' src={`${process.env.PUBLIC_URL}/assets/TPN_GoldLogoTransparent_1280.png`} className={classes.badge} /></Tooltip>;
  if (!props.assessorLevel && props.blueBadgeLabel) {
    const title = (
         <span>
           {props.blueBadgeLabel}
           {props.goldBadgeLabel && (
             <>
               <br />
               {props.goldBadgeLabel}
             </>
           )}
         </span>
    );
     
    const imgSrc = `${process.env.PUBLIC_URL}/assets/TPN_${props.goldBadgeLabel ? 'Gold' : 'Blue'}LogoTransparent_1280.png`;
     
    icon = (
         <Tooltip title={title}>
           <Box component='img' src={imgSrc} className={classes.badge} />
         </Tooltip>
    );
  }
  
  const getAvatar = (imgSrc: string, fileName: string) => (
    <Avatar
      onClick={(e) => {
        e.stopPropagation();
        if (props.goldBadgeLabel || props.blueBadgeLabel || badge) {
          downloadWithWhiteBackground(imgSrc, fileName);
        }
      }}
      sx={{
        backgroundColor: 'white',
        minWidth: '40px',
        minHeight: '40px',
        width: 'fit-content',
        height: 'fit-content',
        p: '0.5rem',
      }}
    >
      {icon}
    </Avatar>
  );

  const imgSrc = `${process.env.PUBLIC_URL}/assets/TPN_${
    props.goldBadgeLabel || badge === 'tpn_assessed' ? 'Gold' : 'Blue'
  }LogoTransparent_1280.png`;

  const fileName = `TPN_${
    props.goldBadgeLabel || badge === 'tpn_assessed' ? 'Gold' : 'Blue'
  }_Badge.png`;

  if (!props.company || !props.isActive || !(user?.companies?.length && user.companies[0].id === props.company)) {
    return getAvatar(imgSrc, fileName);
  }

  if (!props.assessorLevel) {
    if (badge === 'tpn_self_reported') {
      return getAvatar(
        `${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`,
        'TPN_Blue_Badge.png',
      );
    }

    if (badge === 'tpn_assessed') {
      return getAvatar(
        `${process.env.PUBLIC_URL}/assets/TPN_GoldLogoTransparent_1280.png`,
        'TPN_Gold_Badge.png',
      );
    }
  }

  return getAvatar(imgSrc, fileName);
}