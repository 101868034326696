import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../app/store/userSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import getAuthSession from '../../services/auth';
import { setShowTokenExpiredModal } from '../../app/store/userSlice';

export default function NestedAccountList() {
  const [openNav, setOpen] = useState(false);
  const [loginSession, setLoginSession] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!openNav);
  };
  const { signOut } = useAuthenticator();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const getJwtExpiry = async () => {
      const authSession = await getAuthSession();
      const token = authSession.getIdToken().getJwtToken();
      return JSON.parse(window.atob(token.split('.')[1])).exp * 1000;
    };
    const interval = setInterval(async () => {
      const newSession = moment.utc(await getJwtExpiry() - Date.now()).format('HH:mm:ss');
      setLoginSession(newSession);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (loginSession !== '00:00:00') return;
    // Token expired - Show modal
    dispatch(setShowTokenExpiredModal(true));
  }, [loginSession]);

  return (
    <List
      component="nav"
      sx={{
        position: 'absolute',
        bottom: openNav ? 32 : 0,
        width: 'inherit',
        height: 145,
        textAlign: 'center',
      }}>
      <Typography>Logout in: {loginSession}</Typography>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SettingsOutlinedIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="My Account" />
        {openNav ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openNav} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to='/user-profile' title='User Profile'>
            <ListItemButton sx={{ pl: 4 }} >
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='User Info' sx={{ color: 'white' }} />
            </ListItemButton>
          </Link>
          <ListItemButton sx={{ pl: 4 }} onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlinedIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
